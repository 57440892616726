(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TkbTransferController', TkbTransferController);

    TkbTransferController.$inject = ['$state', '$window', '$http', 'DateUtils', 'TkbTransfer', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'ProfileIdByPhone'];

    function TkbTransferController($state, $window, $http, DateUtils, TkbTransfer, ParseLinks, AlertService, paginationConstants, pagingParams, ProfileIdByPhone) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.openProfile = openProfile;
        vm.search = search;
        vm.excel = excel;
        vm.clear = clear;
        vm.externalId = null;
        vm.checkList = [];
        vm.getTransferState = getTransferState;
        vm.confirmTransfer = confirmTransfer;
        vm.initPopover = initPopover;
        vm.initCreditPopover = initCreditPopover;
        vm.searchQuery = {
            userPhone: pagingParams.userPhone,
            externalId: pagingParams.externalId,
            merchant: pagingParams.merchant,
            receiverOwner: pagingParams.receiverOwner,
            receiverCardNumber: pagingParams.receiverCardNumber,
            state: pagingParams.state,
            creditState: pagingParams.creditState,
            country: pagingParams.country,
            gateway: pagingParams.gateway,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            from: pagingParams.from,
            to: pagingParams.to
        };

        initDatePicker();
        loadAll();

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.from = false;
        vm.datePickerOpenStatus.to = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getDateTimeFromPicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }

        function initDatePicker() {
            if (vm.searchQuery.toDate === null) {
                vm.searchQuery.to = new Date();
                pagingParams.toDate = vm.searchQuery.to;
            }
            if (vm.searchQuery.fromDate === null) {
                vm.searchQuery.from = new Date();
                vm.searchQuery.from.setMonth(vm.searchQuery.from.getMonth() - 1);
                pagingParams.fromDate = vm.searchQuery.from;
            }
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.from);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.to);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function loadAll() {
            getDateTimeFromPicker();
            TkbTransfer.query({
                userPhone: vm.searchQuery.userPhone,
                externalId: vm.searchQuery.externalId,
                merchant: vm.searchQuery.merchant,
                receiverOwner: vm.searchQuery.receiverOwner,
                receiverCardNumber: vm.searchQuery.receiverCardNumber,
                state: vm.searchQuery.state,
                creditState: vm.searchQuery.creditState,
                country: vm.searchQuery.country,
                gateway: vm.searchQuery.gateway,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                from: vm.searchQuery.from,
                to: vm.searchQuery.to,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tkbTransfers = data;
                vm.page = pagingParams.page;
                setTimeout(function () {
                    enableClipboard();
                }, 0);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                userPhone: vm.searchQuery.userPhone,
                externalId: vm.searchQuery.externalId,
                merchant: vm.searchQuery.merchant,
                receiverOwner: vm.searchQuery.receiverOwner,
                receiverCardNumber: vm.searchQuery.receiverCardNumber,
                state: vm.searchQuery.state,
                creditState: vm.searchQuery.creditState,
                country: vm.searchQuery.country,
                gateway: vm.searchQuery.gateway,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                from: vm.searchQuery.from,
                to: vm.searchQuery.to
            });
        }

        function search() {
            getDateTimeFromPicker();
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.searchQuery = {
                userPhone: null,
                externalId: null,
                merchant: 'ALL',
                receiverOwner: null,
                receiverCardNumber: null,
                state: null,
                creditState: null,
                country: 'ALL',
                gateway: 'ALL',
                fromDate: null,
                toDate: null,
                from: null,
                to: null
            };
            initDatePicker();
            vm.transition();
        }

        function openProfile(phoneNumber) {
            ProfileIdByPhone.get({phoneNumber: phoneNumber}, onSuccess);

            function onSuccess(data) {
                var url = $state.href('profile-detail', {id: data.profileId});
                $window.open(url, '_blank');
            }
        }

        function excel() {
            getDateTimeFromPicker();
            var fromDate = vm.searchQuery.fromDate === "" ? null : DateUtils.converterDateTimeFormat(vm.searchQuery.fromDate);
            var toDate = vm.searchQuery.toDate === "" ? null : DateUtils.converterDateTimeFormat(vm.searchQuery.toDate);
            var data = (vm.searchQuery.userPhone == null ? "" : "userPhone=" + vm.searchQuery.userPhone)
                + (vm.searchQuery.externalId == null ? '' : "&externalId=" + vm.searchQuery.externalId)
                + (vm.searchQuery.merchant == null ? 'ALL' : "&merchant=" + vm.searchQuery.merchant)
                + (vm.searchQuery.receiverOwner == null ? '' : "&receiverOwner=" + vm.searchQuery.receiverOwner)
                + (vm.searchQuery.receiverCardNumber == null ? '' : "&receiverCardNumber=" + vm.searchQuery.receiverCardNumber)
                + (vm.searchQuery.state == null ? '-1' : "&state=" + vm.searchQuery.state)
                + (vm.searchQuery.creditState == null ? '-1' : "&creditState=" + vm.searchQuery.creditState)
                + (vm.searchQuery.country == null ? 'ALL' : "&country=" + vm.searchQuery.country)
                + (vm.searchQuery.gateway == null ? 'ALL' : "&gateway=" + vm.searchQuery.gateway)
                + "&fromDate=" + fromDate
                + '&toDate=' + toDate;
            $http({
                url: 'api/tkb-transfers-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'tkb-transfers-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {
            });

        }

        function getTransferState(externalId) {
            vm.checkList[externalId] = true;
            TkbTransfer.getTransferState({externalId: externalId}, onSuccess, onError);

            function onSuccess() {
                vm.checkList[externalId] = false;
                vm.externalId = null;
                loadAll();
            }

            function onError(error) {
                vm.checkList[externalId] = false;
                AlertService.error(error.data.message);
            }
        }

        function confirmTransfer(externalId) {
            vm.checkList[externalId] = true;
            TkbTransfer.confirmTransfer({externalId: externalId}, onSuccess, onError);

            function onSuccess() {
                vm.checkList[externalId] = false;
                vm.externalId = null;
                loadAll();
            }

            function onError(error) {
                vm.checkList[externalId] = false;
                AlertService.error(error.data.message);
            }
        }

        function enableClipboard() {
            var clipboardClass = document.getElementsByClassName('mt-clipboard');
            var clipboard = new Clipboard(clipboardClass);
        }

        function initPopover($event, transfer) {
            if (!transfer.message) {
                return;
            }
            var message = transfer.message;
            var elementId = transfer.id;

            var popoverContent =
                '<div style="display: flex" id="popover-text">' +
                '<a id="copy-btn" style="padding: 5px 5px; font-size: 12px;"> ' + message + '</a>' +
                '</div>';

            angular.element('#' + elementId).popover({
                html: true,
                content: popoverContent,
                trigger: 'manual'
            }).popover('toggle');

            angular.element('[data-toggle="popover"]').not('#' + elementId).popover('hide');

            setTimeout(function () {
                var copyBtn = document.getElementById('copy-btn');
                if (copyBtn) {
                    copyBtn.addEventListener('click', function () {
                        copyPopoverText();
                    });
                }
            }, 100);

            angular.element(document).on('mousedown.popoverClose', function (e) {
                if (!angular.element(e.target).closest('.popover').length && !angular.element(e.target).is('#' + elementId)) {
                    angular.element('#' + elementId).popover('hide');
                    angular.element(document).off('mousedown.popoverClose');
                }
            });
        }

        function initCreditPopover($event, transfer) {
            if (!transfer.creditMessage) {
                return;
            }
            var creditMessage = transfer.creditMessage;
            var elementId = transfer.id + '-credit';

            var popoverContent =
                '<div style="display: flex" id="popover-text">' +
                '<a id="copy-btn" style="padding: 5px 5px; font-size: 12px;"> ' + creditMessage + '</a>' +
                '</div>';

            angular.element('#' + elementId).popover({
                html: true,
                content: popoverContent,
                trigger: 'manual'
            }).popover('toggle');

            angular.element('[data-toggle="popover"]').not('#' + elementId).popover('hide');

            setTimeout(function () {
                var copyBtn = document.getElementById('copy-btn');
                if (copyBtn) {
                    copyBtn.addEventListener('click', function () {
                        copyPopoverText();
                    });
                }
            }, 100);

            angular.element(document).on('mousedown.popoverClose', function (e) {
                if (!angular.element(e.target).closest('.popover').length && !angular.element(e.target).is('#' + elementId)) {
                    angular.element('#' + elementId).popover('hide');
                    angular.element(document).off('mousedown.popoverClose');
                }
            });
        }

        function copyPopoverText() {
            var textElement = document.getElementById('popover-text');
            if (!textElement) return;

            var textArea = document.createElement('textarea');
            textArea.value = textElement.innerText;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }
    }
})();
